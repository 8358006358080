import React from "react";
import "./Abc.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Abc() {
  return (
    <>
      <Helmet>
        <title>
          Information Technology For Planning, Architectural Design And Built
          Environment Management
        </title>
      </Helmet>
      <div className="menu">
        <h5>
          <Link to="/">{"< Home"}</Link>
        </h5>
        <div className="main">
          <h2>
            INFORMATION TECHNOLOGY FOR PLANNING, <br />
            ARCHITECTURAL DESIGN AND BUILT ENVIRONMENT MANAGEMENT
          </h2>
          <h3>Main Objectives</h3>
          <p className="desc">
            This course aims to provide some fundamental notions of computer
            science with particular focus on computer programming and data
            modelling and to show their application in the fields of Building
            Information Modelling (BIM).
            <br />
            The computer programming part is based on Python, a well-known and
            widely-used programming language, and is targeted to develop the
            capability of writing general Python programs. It will also cover
            the use of Python to access databases and to manipulate Industry
            Foundation Classes (IFC) files. The data modelling part deals with
            the tools and methodologies for information modelling both at
            conceptual and at physical level, with particular emphasis on
            interoperability with examples taken from real-life interoperability
            models. This part of the course will also present the main concepts
            underlying recent Information Modelling standards in the fields of
            BIM.
          </p>
          <h3>Teaching Organization</h3>
          <p className="desc">
            The course consists of 20 hours of theoretical lectures and 10 of
            exercises. <br />
            For the exam, students must develop a project in Python under the
            guidance of the instructors.
            <p>
              Physical presence is strongly recommended given that some of the
              lectures will be hands-on labs.{" "}
              <u>Only when requested via e-mail</u>, lectures will be streamed
              using WebEx.
            </p>
            <LectureItem
              name="Prof. Quattrocchi virtual room"
              url="https://politecnicomilano.webex.com/meet/giovanni.quattrocchi"
            />
            <LectureItem
              name="Prof. Baresi virtual room"
              url="https://politecnicomilano.webex.com/meet/luciano.baresi"
            />
          </p>
          <h3>Topics</h3>
          <ul>
            <li>
              Coding: <i>basic concepts and motivations</i>
            </li>
            <li>
              Python: <i>variables, types and control flow</i>
            </li>
            <li>
              Python: <i>data structures and functions</i>
            </li>
            <li>
              Python: <i>files and charts</i>
            </li>
            <li>
              Modelling: <i>relational databases and SQL in Python </i>
            </li>
            <li>
              Modelling:{" "}
              <i>bulding modelling with IFC and related Python tools</i>
            </li>
          </ul>
          <h3>Course Schedule and Material (tbd) </h3>
          { /*
          <ul>
            <Lecture name="14/04/22, 14.15-18.15, Prof. Quattrocchi - Aula Castigliano, bldg. 5">
              <LectureItem name="Slides" url="slides/abc/2022/ABC-1.pdf" />
              <LectureItem
                newtab
                name="REPL"
                url="https://replit.com/@gioenn/ABC-2022-1"
              />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/webappng/sites/politecnicomilano/recording/e2fc21dc9e1a103aafbf0050568193e1/playback"
              />
            </Lecture>

            <Lecture name="21/04/22, 14.15-18.15, Prof. Baresi - Aula G1, bldg. 11">
              <LectureItem
                name="Exercises"
                url="slides/abc/2022/ABC-1-ex.pdf"
              />
              <LectureItem name="Slides" url="slides/abc/2022/ABC-2.pdf" />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/recordingservice/sites/politecnicomilano/recording/a57195f8a39b103ab3b70050568174b2/playback"
              />
            </Lecture>
            <Lecture name="26/04/22, 14.15-18.15, Prof. Baresi - Aula Castigliano, bldg. 5">
              <LectureItem
                name="Exercises"
                url="slides/abc/2022/ABC-2-ex.pdf"
              />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/recordingservice/sites/politecnicomilano/recording/97a8ae53a789103ab7fa0050568124ba/playback"
              />
            </Lecture>
            <Lecture name="28/04/22, 14.15-18.15, Prof. Baresi - Aula Didattica (Sala Formazione), bldg. 14">
              <LectureItem
                name="Exercises"
                url="slides/abc/2022/ABC-3-ex.pdf"
              />
              <LectureItem
                name="An exercise on files"
                url="slides/abc/2022/ABC-3-ex-files.zip"
              />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/recordingservice/sites/politecnicomilano/recording/7b3260c9a91e103a9bbf005056811134/playback"
              />
            </Lecture>
            <Lecture name="03/05/22, 14.15-18.15, Prof. Quattrocchi - Aula 8.1.1, bldg. 8">
              <LectureItem name="Exercise Lecture (not recorded)" />
            </Lecture>
            <Lecture name="05/05/22, 14.15-18.15, Prof. Quattrocchi - Aula G.4, bldg. 11">
              <LectureItem name="Slides" url="slides/abc/2022/ABC-4.pdf" />
              <LectureItem
                name="Exercises"
                url="slides/abc/2022/ABC-4-ex.zip"
              />
              <LectureItem
                newtab
                name="REPL"
                url="https://replit.com/@gioenn/FilesAndMore"
              />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/webappng/sites/politecnicomilano/recording/c64f327bae9b103a97fe00505681283b/playback"
              />
            </Lecture>
            <Lecture name="10/05/22, 14.15-18.15, Prof. Quattrocchi - Sala Formazione, bldg. 14">
              <LectureItem name="Slides" url="slides/abc/2022/ABC-5.pdf" />
              <LectureItem
                name="Exercises"
                url="slides/abc/2022/ABC-5-ex.zip"
              />
              <LectureItem
                name="Demo on DB and IFC"
                url="slides/abc/2022/ABC-5-demo.zip"
              />
              <LectureItem
                newtab
                name="Recording"
                url="https://politecnicomilano.webex.com/recordingservice/sites/politecnicomilano/recording/9b5cf8eab28a103aa7ef00505681d089/playback"
              />
            </Lecture>
          </ul>
          <ul>
            <Lecture name="Exam">
              <LectureItem name="09/06/22, 14.15-18.15, Aula Beta, bldg. 24" />
              <LectureItem
                name="Instructions"
                url="slides/abc/2022/ABC-exam.pdf"
              />
            </Lecture>
          </ul>
        */}
        </div>
      </div>
    </>
  );
}

/*
function Lecture(props) {
  return (
    <li>
      <b>
        {props.name}
        <ul>{props.children}</ul>
      </b>
    </li>
  );
}
*/

function LectureItem(props) {
  return (
    <li>
      {(props.url && (
        <a
          rel="noreferrer"
          target={props.newtab ? "blank" : ""}
          href={props.url}
        >
          {props.name}
        </a>
      )) ||
        props.name}
    </li>
  );
}



export default Abc;