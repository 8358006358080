import React, { Component } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Home from "./Home";
import Abc from "./Abc";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function App() {
  return (
    <Router>
      <ScrollToTopI />
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/abc">
            <Abc />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <React.Fragment />;
  }
}

let ScrollToTopI = withRouter(ScrollToTop);
