import "./Home.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Giovanni Quattrocchi</title>
      </Helmet>
      <div className="home">
        <div className="main">
          <div className="row">
            <div className="pic">
              <img src="gq.jpg" alt="GQ" />
            </div>
            <div className="bio">
              <h2>Giovanni Ennio Quattrocchi, Ph.D.</h2>
              <h4>assistant professor</h4>
              <b>
                <a href="mailto:giovanni.quattrocchi@polimi.it">
                  giovanni.quattrocchi@polimi.it
                </a>
              </b>
              <br />
              <b>Via Golgi 42, Milano, 20133</b> <br />
              <b> Building 22, Floor: 3, Room: 003</b>
            </div>
          </div>
          <br />
          <br />
          <div className="data">
            <i>
              "Dave, do you mind if I ask you a personal question?" — HAL 9000
            </i>
            <h4 className="biolink">
              <a href="CV.pdf">Curriculum Vitae</a>{" "}
              <a href="https://scholar.google.com/citations?user=jRU2xZMAAAAJ&hl=it&oi=ao">
                Google Scholar
              </a>{" "}
              <a href="https://dblp.org/pid/150/6029.html">dblp</a>
            </h4>
            <section>
              <h3> Teaching </h3>
              <ul>
                <Teaching
                  name="Software Engineering for Geoinformatics"
                  url="https://webeep.polimi.it/course/view.php?id=8620"
                  detail="WeBeep"
                />
                <Teaching
                  name="Ingegneria Del Software - IOL"
                  url="https://webeep.polimi.it/course/view.php?id=7097"
                  detail="WeBeep"
                />
                <Teaching
                  name="Distributed Software Development"
                  url="https://www.fer.unizg.hr/rasip/dsd"
                  detail="course page"
                />
               
              </ul>
            </section>
            <section>
              <h3>Available Theses</h3>
              Just the main ideas and some related published papers or past thesis, contact me for more details.
              <ul>
                <li className="thesis">
                  Design and implemenation of trustworthy systems using Zero Knowledge Proofs
                  <ul className="thesis-items">
                    <li>
                      Zero Knowledge Proofs and their application on Machine Learning (<a href="papers/zk-survey.pdf">thesis summary</a>)
                    </li>
                    <li>
                      Decentralized Federated Machine Learning with Blockchain and Zero Knowledge Proofs (<a href="papers/zk-fl.pdf">thesis summary</a>)
                    </li>
                  </ul>
                </li>
                <li className="thesis">
                  Autoscaling systems for container-based applications
                  <ul className="thesis-items">
                    <li>
                      Vertical and horizontal multi-level resource allocation in Kubernetes (<a href="papers/kosmos.pdf">paper</a>)
                    </li>
                    <li>
                      Runtime management of Edge computing infrastructures (<a href="https://arxiv.org/pdf/2205.04320.pdf">paper</a>)
                    </li>
                    <li>
                      Coordinated CPU and GPU allocation for Machine Learning systems (<a href="papers/roma.pdf">paper</a>)
                    </li>
                  </ul>
                </li>
                <li className="thesis">
                  Blockchain-based applications and tools
                  <ul className="thesis-items">
                    <li>
                      Automated and model-based deployment of smart contracts (<a href="https://arxiv.org/pdf/2209.05092.pdf">paper</a>)
                    </li>
                    <li>
                      Decentralized voting- and reputation-based blockchain oracles (<a href="https://arxiv.org/pdf/2209.11032.pdf">paper</a>)
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

function Teaching(props) {
  return (
    <li className="teaching">
      <>
        {props.name} (
        {(props.link && <Link to={props.url}>{props.detail}</Link>) || (
          <a href={props.url}>{props.detail}</a>
        )}
        )
      </>
    </li>
  );
}

export default Home;
